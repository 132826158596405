import * as _development3 from "@babel/plugin-transform-react-jsx/lib/development";

var _development2 = "default" in _development3 ? _development3.default : _development3;

var exports = {};
Object.defineProperty(exports, "__esModule", {
  value: true
});
Object.defineProperty(exports, "default", {
  enumerable: true,
  get: function () {
    return _development.default;
  }
});
var _development = _development2;
export default exports;
export const __esModule = exports.__esModule;